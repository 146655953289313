import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css"
import PrivateRoute from "./PrivateRoutes/PrivateRoute";
import './Bootstrap/css/bootstrap.min.css'
import { useDispatch, useSelector } from "react-redux";
const Auth = lazy(() => import("./Components/Authentication/Auth"))
const RoutingConfig = lazy(() => import("./Components/RoutingConfig"))
const Home = lazy(() => import("./Components/Authentication/Home"))
const ContactUs = lazy(() => import("./Components/Authentication/ContactUs"))
const TermsandConditions = lazy(() => import("./Components/Authentication/TermsandCondition"))
const PrivacyPolicy = lazy(() => import("./Components/Authentication/PrivacyPolicy"))
const CreatePassword = lazy(() => import("./Components/Authentication/ResetPassword"))

const App = () => {
  return (
    <Suspense fallback={() => <div>Loading ....</div>}>
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/terms&conditions" element={<TermsandConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/createpassword/:token" element={<CreatePassword />} />
          <Route path="*" element={<Home />} />
          <Route path="v1/*" element={<PrivateRoute children={<RoutingConfig />} />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;