export const changeState = (payload) => ({
    type: "CHANGESTATE",
    payload,
})

export const changeModalState = (payload) => ({
    type: "CHANGEMODALSTATE",
    payload
})

export const changeDataState = (payload) => ({
    type: "CHANGEDATASTATE",
    payload
})
